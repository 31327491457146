import React, { useEffect, useState } from 'react';
import s from './Cleaning.module.css';
import Header from '../../components/Header/Header';
import PromoBox from '../../components/PromoBox/PromoBox';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup';
import img from '../../images/cleaning.png';
import axios from 'axios';
import { URL_API } from '../../utils/api';
import { useNavigate } from 'react-router-dom';

export default function Cleaning({ spiceId, promocodeHash }) {
  const [promocodeValue, setPromocodeValue] = useState();
  const [popup, setPopup] = useState(false);

  const navigator = useNavigate();

  const closePopup = () => {
    setPopup(false);
  };
  const openPopup = () => {
    setPopup(true);
  };

  useEffect(() => {
    localStorage.setItem('page', '/cleaning');

    if (!localStorage.getItem('first')) {
      axios
        .post(`${URL_API}api/web_bot/promocode`, {
          spice_id: spiceId || localStorage.getItem('spice_id'),
          hash: promocodeHash || localStorage.getItem('promocode_hash'),
        })
        .then((response) => {
          localStorage.setItem('first', 'true');
          setPromocodeValue(response.data.data.Code);
        })
        .catch((error) => {
          openPopup();
          console.log(error);
        });
    } else {
      axios
        .get(
          `${URL_API}api/web_bot/promocode?spice_id=${
            spiceId || localStorage.getItem('spice_id')
          }&hash=${promocodeHash || localStorage.getItem('promocode_hash')}`
        )
        .then((response) => {
          setPromocodeValue(response.data.data.Code);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setPromocodeValue(err.response.status);
          }
          openPopup();
        });
    }
  }, []);

  return (
    <div className={s.page}>
      <Header transparent={true} />
      <div className={s.container}>
        <PromoBox
          title="Бесплатная профессиональная чистка"
          img={img}
          promocode={true}
          buttonLink="https://www.iqos.ru/address/gde-kupit/?services=shop_official"
          promocodeValue={promocodeValue}
        />
      </div>
      {popup && <ErrorPopup closePopup={closePopup} />}
    </div>
  );
}
